import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Curs Exprés de Personalitat i Aptitudinals</Title>
                <Text>
                    Un curs específic per a preparar la prova aptitudinal i la prova psicotècnica de
                    la convocatòria d'agents rurals.
                    <br />
                    <br />
                    Dissabte 14 de gener de 9,00 a 15.00 h
                    <br />
                    <br />
                    6 hores presencials.
                    <br />
                    <br />
                    El curs és impartit per un psicòleg especialista amb més de trenta anys
                    d'experiència.
                    <br />
                    <br />
                    ✔️ En el curs s'impartiran els conceptes claus de la prova aptitudinal
                    (analogies, cubs, figures, sèries, etc..)
                    <br />
                    <br />
                    ✔️ Orientació del test de personalitat, amb els ítems i les competències
                    exigides segons bases de la convocatòria d'agent rural.
                    <br />
                    <br />
                    És un curs específic per a anar per NOTA
                    <br />
                    <br />
                    El curs és impartit a l'Hospitalet de Llobregat.
                    <br />
                    <br />
                    C/Martí Codolar num 18.
                    <br />
                    <br />
                    Places limitades!
                    <br />
                    <br />
                    Preu 69,99€
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
